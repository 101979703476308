<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%;  vertical-align: middle;'>
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>BI ANALYSER 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		        <span class='SVcontrolLabel'>Settings: </span>
				<div class='input80'>
					<vSelect  placeholder='choose settings' :options="allSettings" style='width:100%' v-model="selectedSettings" @input=setSettings>
				          
				    </vSelect>
				</div>
				<div class='input20'>
			    <button :disabled='! selectedSettings || ! selectedSettings.id' class='button btn' @click="setSettings(selectedSettings)">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
				  <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
				  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
				</svg>
				</button>
				</div>
		    	<InputText v-model="name"/>
		    	<br/>
		    	<div>
			    <div style='display: inline-flex; width: 45%;'>
			    <InputDatePick  :auto="false"
                            v-model="fromDate"/>  
                            </div>
                            -
                <div style='display: inline-flex; width: 45%;'>
                <InputDatePick  :auto="false"
                            v-model="untilDate"/>     
                 </div>           

				</div>
		        <br/>
		    	
		    	<br/>
		        <br/>
			    <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			   
			    <vSelect :disabled='true' placeholder='choose channel' :multiple="true" :options="stations" style='width:100%' v-model="selectedStation">
			          
			    </vSelect>
			    <br/>
			    
				
		    	
		    	<span class='SVcontrolLabel'>Filter: </span><br/>
		    		<div v-for="(elem,key,idx) in activeFilter" :key="'flt_'+idx" style='display: block; font-size: 9pt; white-space: nowrap;'>
		    		{{key}} 

					  
		    		<vSelect :disabled='true' placeholder='choose filter' :multiple="true" :options="elem" style='width:80%' v-model="activeFilter[key]" />
		              
		            
		            </div>
		    	<br/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>
		    	
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 77%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 10pt; '>
		
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: calc(95%); height: calc(100vh - 120px); '>
	    	<span class='SVcontrolLabel'>GROUPING</span>
	        <div style='border: 1pt outset #ccc; width: 100%; ' 
	              @dragover.prevent
				  @dragenter.prevent
	              @drop.exact="dropField($event, 'G', 999)" 
	         >
	            <span v-if="! columnsGroupingActive.length" style='color: #aaa; font-weight: bold; font-size: 10pt; padding-left: 10pt; ' >no grouping</span>
	            <span v-else>
	            
	        	<span class='reportItem' v-for="(display, idx) in columnsGroupingActive" :key="'g_'+idx" draggable
	        	   @dragstart.exact="dragStart($event, 'G', idx)"
		           @dragend.exact="dragEnd($event, 'G', idx)" 
		           @dragover.prevent
				   @dragenter.prevent
				   
		           @drop.exact="dropField($event, 'G', idx)" 
	        	  >
	        	   {{display}}
	        	</span>
	        	</span>
	        </div>

	       
	    	<br/>
	    	<span class='SVcontrolLabel'>KPIs</span>
	    	
	        <div style='border: 1pt outset #ccc; width: 100%; height: 20pt;' 
	              @dragover.prevent
				  @dragenter.prevent
	              @drop.exact="dropFieldKPI($event, 'K', 999)" 
	         >
	            <span v-if="! columnsKPIActive.length" style='color: #aaa; font-weight: bold; font-size: 10pt; padding-left: 10pt; ' >no kpis</span>
	            <span v-else>
	        	<span class='reportItem' v-for="(display, idx) in columnsKPIActive" :key="'k_'+idx" draggable
	        	   @dragstart.exact="dragStartKPI($event, 'K', idx)"
		           @dragend.exact="dragEndKPI($event, 'K', idx)" 
		           @dragover.prevent
				   @dragenter.prevent
		           @drop.exact="dropFieldKPI($event, 'K', idx)" 
	        	  >
	        	   {{display}}
	        	</span>
	        	</span>
	        </div>
	        
	        
	    	<br/>
	    	
	    	<button class='button myButton' @click='getDataJSON("json")'>JSON</button>
	    	
	    	<button class='button myButton' @click='getDataJSON("table")'>Table</button>
	    
	    	<button class='button myButton' @click='getData()'>Excel</button>
	    	<span style='display: inline-flex; margin-top: 7pt;'>
	    	<clipboard v-show='json' title='Copy JSON structure to clipboard'  :value="JSON.stringify(json)" ><icon-write /></clipboard>
	    	</span>
		    	<br/>
	    	<div v-show='json' style='font-size: 10pt; border: 2pt outset #ccc; height: calc(40%); overflow-y: scroll;'>
	    	
	    	{{json}}
	    	</div>  
	    	
	    	<GTableJSON ref='dataTable' v-show='jsonTable' height="400" :json="jsonTable" 
	    	            @cell-contextmenu="ctxMenu">
	    	</GTableJSON>
	    	
	    	

    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    
   
   <ContextMenu ref="menu" :offsetY="-50">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="addToFilter(contextData)">
        	add '{{contextData.column}}={{contextData.data}}' to the filter 
        </ContextMenuItem>


       </template>
    </ContextMenu>
    
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, setReload, myLocale} from '@/variables.js';
import { getAppStyle, initAppMode } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import InputText from '@/components/inputElements/InputText';
import GTableJSON from '@/components/GTableJSON';
import clipboard from '@/components/Clipboard';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

var timers = [];

export default {
  name: 'GFW_Spot_Analyser',
  components : {
   'app-switch': Switch, InputDatePick, vSelect, InputText, GTableJSON, clipboard, ContextMenu, ContextMenuItem
  },
  data () {
    return {
      stationId: 0,
      station: {},
      chartType: 'bar',
      chartData: [],
      chart_config: "",
      selectedStation: {},
      currency: {},
      stations: [],
      activeFilter: {},
      columnsGrouping: [],
      columnsGroupingActive: [],
      columnsKPI: [],
      columnsKPIActive: ['Count'],
      json: null,
      jsonTable: null,
      name: "",
      fromDate: {},
      untilDate: {},
      selectedSettings: {},
      allSettings: [],
      settings: [],
      loadingActive: false,
      controlsRight: false,
      
      allDetailIDs: [],
      detailEntityMap: {},
      selectedDetail: {},
      metadataDetail: {},
      detailCounter: 0,
      showEditorDetail: false,
    }
  },    
  methods: {
    myLocale() { return myLocale();},
    addAllKPIs()
    {
    	if ( this.columnsKPIActive.length )
    	{
    		this.columnsKPIActive = [];
    	}
    	else
    	{
    		for ( let c in this.columnsKPI )
    		{
    			this.addToKPI(this.columnsKPI[c]);
    		}
    	}
    },
    addAllGroupings()
    {
    	if ( this.columnsGroupingActive.length )
    	{
    		this.columnsGroupingActive = [];
    	}
    	else
    	{
    		for ( let c in this.columnsGrouping )
    		{
    			this.addToGrouping(this.columnsGrouping[c]);
    		}
    	}
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    },
    tableClick(rowIndex, columnIndex, data)
    {
    	
    	//this.activeFilter = filter;
    },
    removeFromFilter( x)
    {
		if ( this.activeFilter[x.column] )
    	{
    		let value = this.activeFilter[ x.column].filter( p => p.label != x.data);
    		if ( value.length == 0 )
    		{
    		    this.$set( this.activeFilter, x.column, value);
    			delete this.activeFilter[ x.column];
    		}
    		else
    		{
    			this.$set( this.activeFilter, x.column, value);
    		}
    	}
    },
    addToFilter( x)
    {
    	if ( ! this.activeFilter[x.column] )
    	{
    		this.$set( this.activeFilter, x.column, [{id: 1, label: x.data}]);
    	}
    	else
    	{
    		if ( ! this.activeFilter[ x.column].includes( x.data ))
    		{
	    		let value = this.activeFilter[ x.column];
	    		
	    		value.push({id: value.length+1, label: x.data}); 
	    		this.$set( this.activeFilter, x.column, value);
	    	}
    	}
    },
    ctxMenu(event, rowIndex, columnIndex, data) 
      { 
         
          if ( rowIndex > 0 )
          {
            let row = this.jsonTable[rowIndex-1];
	    	let filterTmp = [];
	    	let filter = {};
	    	let i = 0;
	    	for ( let c in this.columnsGroupingActive )
	    	{
	    		let col = this.columnsGroupingActive[ c];
	    		filterTmp[ i++] = { column: col, data: row[ col] };
	    	}
	    	
	    	if ( columnIndex >= 0 && columnIndex < filterTmp.length)
	    	{
	    		filter = filterTmp[columnIndex];
	    		
            	this.$refs.menu.open(event, filter )
	    	}
            
          }
      },
    inGrouping(display)
    {
    	return this.columnsGroupingActive.find(p => p === display)
    },
    inKPI(display)
    {
    	return this.columnsKPIActive.find(p => p === display)
    },
    addToGrouping(display) {
        if ( ! this.inKPI( display) && ! this.inGrouping( display))
        {
    		this.columnsGroupingActive.push(display);
    	}
    	else
    	{
    		this.columnsGroupingActive = this.columnsGroupingActive.filter(p => p !== display)
    	}
    },
    addToKPI(display) {
        if ( ! this.inKPI( display) && ! this.inGrouping( display))
        {
    		this.columnsKPIActive.push(display);
    	}
    	else
    	{
    		this.columnsKPIActive = this.columnsKPIActive.filter(p => p !== display)
    	}
    },
    dragStart(evt, from, idx)
    {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('idx', idx)
        evt.dataTransfer.setData('src', from)
    },
    dragEnd(evt, from, idx)
    {
    },
    getClassGroup( display )
    {
    	if ( this.inKPI( display) || this.inGrouping( display))
    	{
    		return "reportItemUsed"; 
    	}
    	return "reportItem"; 
    },
    setSettings(activeSettings)
    {
    	this.name = activeSettings.label;
		let settings = this.settings.find(p => p.entity.id === activeSettings.id);
		this.json = null;
		this.activateSettings( settings)
    },
    
    dropField(evt, here, idx)
    {
       event.stopPropagation();
       let fieldSrc = evt.dataTransfer.getData('src');
       let fieldIdx = evt.dataTransfer.getData('idx');
       if ( fieldSrc === here && here === 'G' )
       {
       		
       		if ( idx < fieldIdx)
            {
       			let field = this.columnsGroupingActive[fieldIdx];
	       		this.columnsGroupingActive.splice(idx, 0, field);
	       		this.columnsGroupingActive.splice(parseInt(fieldIdx)+1, 1);
	       		//this.columnsGroupingActive = this.columnsGroupingActive.filter(p => p !== field);
	       		//this.columnsGroupingActive = this.columnsGroupingActive.map(p => (p==='<here>')?field:p);
       		}
       		else
       		{
       			let field = this.columnsGroupingActive[fieldIdx];
	       		this.columnsGroupingActive.splice(idx+1, 0, field);
	       		this.columnsGroupingActive.splice(parseInt(fieldIdx), 1);
       		} 
       	}
       	else if ( fieldSrc !== here && here === 'G')
        {
        	let field = this.columnsGrouping[fieldIdx];
        	if ( !this.inGrouping( field))
        	{
	        	if ( idx === 999 )
	       		{
	       			this.columnsGroupingActive.push(field);
	       		}
	            else 
	            {
	        		this.columnsGroupingActive.splice(idx, 0, field);
	        	}
	        }
        } 	
        else if ( fieldSrc !== here && here === 'AV')
        {
        	let field = this.columnsGroupingActive[fieldIdx];
        	this.columnsGroupingActive.splice(parseInt(fieldIdx), 1);
        } 	
    },
    dragStartKPI(evt, from, idx)
    {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('idx', idx)
        evt.dataTransfer.setData('src', from)
    },
    dragEndKPI(evt, from, idx)
    {
    },
    dropFieldKPI(evt, here, idx)
    {
       event.stopPropagation();
       let fieldSrc = evt.dataTransfer.getData('src');
       let fieldIdx = evt.dataTransfer.getData('idx');
       if ( fieldSrc === here && here === 'K')
       {
       		if ( idx < fieldIdx)
            {
       			let field = this.columnsKPIActive[fieldIdx];
	       		this.columnsKPIActive.splice(idx, 0, field);
	       		this.columnsKPIActive.splice(parseInt(fieldIdx)+1, 1);
       		}
       		else
       		{
       			let field = this.columnsKPIActive[fieldIdx];
	       		this.columnsKPIActive.splice(idx+1, 0, field);
	       		this.columnsKPIActive.splice(parseInt(fieldIdx), 1);
       		} 
       	}
       	else if ( fieldSrc !== here && here === 'K')
        {
        	let field = this.columnsKPI[fieldIdx];
        	if ( !this.inKPI( field))
        	{
	        	if ( idx === 999 )
	       		{
	       			this.columnsKPIActive.push(field);
	       		}
	            else 
	            {
	        		this.columnsKPIActive.splice(idx, 0, field);
	        	}
	        }
        } 	
        else if ( fieldSrc !== here && here === 'KAV')
        {
        	let field = this.columnsKPIActive[fieldIdx];
        	this.columnsKPIActive.splice(parseInt(fieldIdx), 1);
        } 	
    },
    getStations() {
        let that = this;
		this.startLoader()

        let time = new Date().getTime();
        this.name = "Default " + sessionStorage.login;
    	let stationLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( fwAPI+"/getAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED STATIONS in " + dur +"ms");  
                    var allStations = response.data.data;
                    that.stations = [];
                    for ( var channel in allStations )
                    {
                    	if ( allStations[channel].visible )
                    	{
                    		that.stations.push( allStations[channel]);
                    	}
                    	
                    }   
                    that.selectedStation = JSON.parse( JSON.stringify( that.stations));
                    resolve (that.stationId);                    
                 }).catch(e => {
                        that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
	   stationLoad.then( x => { 
	   		HTTP.post( reportAPI+"/getMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId)
	   		.then( response => 
                 {
                 	let data = response.data;
                 	
                 	for ( let i in data)
                 	{
                 		let def = data[i];
                 		//alert( JSON.stringify(def));
                 		if ( def.grouping && def.display !== 'Count')
                 		{
                 			that.columnsGrouping.push( def.display);
                 		}
                 		if ( def.addable )
                		{
                			that.columnsKPI.push( def.display);
                		}
                 	}
                 	that.columnsGrouping.sort();
                 	that.columnsKPI.sort();
                 	that.loadSettings();
                 	that.loadAllSettings();
                 	
                 }).catch(e => {
                        if ( e.response)
                        {
                        	that.$toast.error("loading metadata for report: " + e.response.data, 'Error', { position: "topRight" });
                     	}
                     	else
                     	{
                     		that.$toast.error("loading metadata for report: " + e, 'Error', { position: "topRight" });
                     	}
                       
                    });
                 
	   });
	      
    },
    forceFileDownload(base64, name) 
	{  
      const url = window.URL.createObjectURL(new Blob([this.base64ToArrayBuffer(base64)]));
      const link = document.createElement('a');
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    },
    base64ToArrayBuffer(base64) {
	    var binary_string = atob(base64);
	    var len = binary_string.length;
	    var bytes = new Uint8Array(len);
	    for (var i = 0; i < len; i++) {
	        bytes[i] = binary_string.charCodeAt(i);
	    }
	    return bytes.buffer;
	},
    getData() {
        let that = this;
       
		this.startLoader();
		var channelIDs = new Array();
		for ( var channel in this.selectedStation )
        {
        	channelIDs.push( this.selectedStation[channel].id)
        }
        this.clearFilters();
		let req = {	channelIds: channelIDs, 
					fromDateISO: new Date(this.fromDate).toISOString().split('T')[0], 
					untilDateISO: new Date(this.untilDate).toISOString().split('T')[0],
					filter: this.activeFilter,
					groupingColumns: this.columnsGroupingActive,
					kpiColumns: this.columnsKPIActive };
        let time = new Date().getTime();
        let settingsId = (this.selectedSettings?this.selectedSettings.id:0);
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/createFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+settingsId);
            
       		HTTP.post( reportAPI+"/createFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+settingsId, req)

        		 .then( response => 
                 {
                 	let downloadResponse = response.data;

	                that.forceFileDownload( downloadResponse.file, downloadResponse.filename);
	                that.stopLoader();
	
                 }).catch(e => {
                        that.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        that.stopLoader();
                        reject ();
                    });
	      });
    },
    getDataJSON( what) {
        let that = this;
       
		this.startLoader();
		var channelIDs = new Array();
		for ( var channel in this.selectedStation )
        {
        	channelIDs.push( this.selectedStation[channel].id)
        }
        this.clearFilters();
		let req = {	channelIds: channelIDs, 
					fromDateISO: new Date(this.fromDate).toISOString().split('T')[0], 
					untilDateISO: new Date(this.untilDate).toISOString().split('T')[0],
					filter: this.activeFilter,
					groupingColumns: this.columnsGroupingActive,
					kpiColumns: this.columnsKPIActive };
        let time = new Date().getTime();
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/createJSON/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		HTTP.post( reportAPI+"/createJSON/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                    if ( what === 'json' )
                    {
                 		that.json = response.data;
                 		that.jsonTable = null;
                 	}
                 	else
                 	{
                 		let columns = that.columnsGroupingActive.concat(that.columnsKPIActive)
                 		that.$refs.dataTable.process( columns, response.data);
                 		that.jsonTable = response.data;
                 		that.json = null;
                 	}
					that.stopLoader();
	
                 }).catch(e => {
                        that.$toast.error("loading data for report: " + e.response.data, 'Error', { position: "topRight" });
                        that.stopLoader();
                        reject ();
                    });
	      });
    },
    // 
    clearFilters()
    {
    	for ( let key in this.activeFilter )
        {
	        if ( this.activeFilter[key] )
	    	{
	    		let value = this.activeFilter[key];
	    		if ( value.length == 0 )
	    		{
	    		    this.$set( this.activeFilter, key, value);
	    			delete this.activeFilter[ key];
	    		}
	    	}
	    }
    },
    saveSettings() {
        let that = this;
        this.startLoader();
		var channelIDs = new Array();
		for ( var channel in this.selectedStation )
        {
        	channelIDs.push( this.selectedStation[channel].id)
        }
        this.clearFilters();
		let req = {	channelIds: channelIDs, 
					fromDateISO: new Date(this.fromDate).toISOString().split('T')[0], 
					untilDateISO: new Date(this.untilDate).toISOString().split('T')[0],
					name: that.name,
					filter: this.activeFilter,
					groupingColumns: this.columnsGroupingActive,
					kpiColumns: this.columnsKPIActive };
        let time = new Date().getTime();
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/saveSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
            
       		HTTP.put( reportAPI+"/saveSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)

        		 .then( response => 
                 {
                 	
                 	that.stopLoader();
					that.name = response.data.name;
					that.selectedSettings = {id: response.data.id, label: response.data.name};
					that.loadAllSettings();
	
                 }).catch(e => {
                        if ( e.response)
                        {
                        	that.$toast.error("saving settings for report: " + e.response.data, 'Error', { position: "topRight" });
                     	}
                     	else
                     	{
                     		that.$toast.error("saving settings for report: " + e, 'Error', { position: "topRight" });
                     	}
                        that.stopLoader();
                        reject ();
                    });
	      });
    },
    activateSettings( data)
    {
    	let that = this;
    	let settings = data.entity.settings;
    	//alert( JSON.stringify(data))
    	if ( data.from && data.until )
    	{
    		that.fromDate = new Date(data.from);
  	    	that.untilDate = new Date(data.until);
  	    }
    	that.selectedStation =  that.stations.filter(p => settings.channelIds.includes(p.id));
		that.name = settings.name;
		that.activeFilter = settings.filter?settings.filter:{};
		that.columnsGroupingActive = settings.groupingColumns;
		that.columnsKPIActive = settings.kpiColumns;
    },
    loadSettings() {
        let that = this;
		let req = {	name: that.name };
        let time = new Date().getTime();
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/loadSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
           
       		HTTP.post( reportAPI+"/loadSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)
        		 .then( response => 
                 {
                    
                 	if ( response.data && response.data.entity )
                 	{
               	
	                 	let settings = response.data;
	                 	
	                 	//that.activateSettings( settings)
						//that.selectedSettings = {id: response.data.entity.id, label: response.data.entity.name};
					}
	
                 }).catch(e => {
                        if ( e.response)
                        {
                        	that.$toast.error("loading settings for report: " + e.response.data, 'Error', { position: "topRight" });
                     	}
                     	else
                     	{
                     		that.$toast.error("loading settings for report: " + e, 'Error', { position: "topRight" });
                     	}
                        reject ();
                    });
	      });
    },
    loadAllSettings() {
        let that = this;
		let req = {	name: that.name };
        let time = new Date().getTime();
        
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( reportAPI+"/getAllSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
           
       		HTTP.post( reportAPI+"/getAllSettings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, req)
        		 .then( response => 
                 {
                 	if ( response.data  )
                 	{
                 	    let data = response.data;
	                 	that.allSettings = [];
	                 	that.settings = data;
	                 	for ( let i in data )
	                 	{
	                 		let setup = data[i].entity;
	                 		that.allSettings.push({id: setup.id, label: setup.name});
	                 	}
					}
	
                 }).catch(e => {
                        if ( e.response)
                        {
                        	that.$toast.error("loading settings for report: " + e.response.data, 'Error', { position: "topRight" });
                     	}
                     	else
                     	{
                     		that.$toast.error("loading settings for report: " + e, 'Error', { position: "topRight" });
                     	}
                        reject ();
                    });
	      });
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	closeEditorDetail()
	    {
	        this.showEditorDetail = false;
	    },
	nodeToOpen( event, module, dataId)
	    {
	        
	    	this.selectedDetail = {};
	    	this.metadataDetail = {};
	    	//alert(metadata.name)
	    	let that = this;
	    	let time = new Date().getTime();
	    	let mapperLoad = new Promise(function (resolve, reject) {
		    
			HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+module+"/allEntitiesWithDate/null/true/1", [dataId])
	        		 .then( response => 
	                 {
	                 	let dur = (new Date().getTime()-time);
	               		//console.log("LOADED rights in " + dur +"ms");  
	                    Object.assign( that.selectedDetail, response.data.data[0]);
	                    that.metadataDetail = response.data.metadata;
	                    that.detailEntityMap = response.data.entity2DataMap;
	                    that.showEditorDetail = true;
	                          
	                 }).catch(e => {
	                        //console.log( JSON.stringify(e));
	                        that.$toast.error("loading data: " + e.response.data, 'Error', { position: "topRight" });
	
	                    });   
			   
		    	});
	    	
	    	//this.$emit(node.module.toLowerCase(), node.entitydata);
	    },
  },
  created() {
  	this.fromDate = new Date();
  	this.untilDate = new Date();
  	this.getStations();
  	initAppMode();
  },
  watch: {

  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.reportItem {
	transition: all .4s;
   -webkit-transition: all .4s;
   font-size: 12pt;
   cursor: pointer;
}
.reportItemUsed {
	transition: all .4s;
   -webkit-transition: all .4s;
   font-size: 12pt;
   color: #aaa;
   text-decoration: line-through;
   font-weight: bold;
   cursor: not-allowed;
}
.reportItem:hover {
	background-color: #eef;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
   
    margin-right: 4pt;

}  

.myButton:hover {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
    border: 1pt outset green;
    background-color: #e8f0e8;
}  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
 }
.availableFields {
  height: 32pt !imoortant;
  //display: inline-flex;
  //overflow: hidden;
  //white-space: nowrap;
  
  transition: all .4s;
   -webkit-transition: all .4s;
}
.availableFields:hover {
	height: auto;
	display: block;
	white-space: normal;
    overflow: hidden;
}


.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}
.SVcontrolLabelAvail {
	padding-top: 9pt;
	font-weight: bold;
	font-size: 9pt;
	cursor: pointer;
	color: #aaa;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
</style>
